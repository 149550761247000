<template>
  <section class="desgin">
    <Loader :loading="showLoader" />
    <div class="row flex-grow">

      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Banner </h4>
            <form @submit.prevent="editAbout" class="forms-sample">
              <div class="row">
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <label class="d-block">Title</label>
                    <div>
                      <textarea v-model="about.title" class="form-control" rows="5"></textarea>
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <label class="d-block">Sub Title</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="about.subtitle" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <label class="d-block">Image</label>
                    <vue-dropzone ref="myVueDropzone" id="thumb " :useCustomSlot="true" :options="thumb"
                      @vdropzone-success="ImgUpload">
                      <div class="dropzone-custom-content">
                        <div class="image-size-dropzone">
                          <div>
                            <img :src="`${imageURL}/${about.banner}`" class="show-img img-fluid" />
                          </div>
                        </div>
                        <div class="subtitle p-2">
                          <span>Change</span>
                          <span style="color: #eb2027"> Image</span>
                        </div>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn mr-2 btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Section 1</h4>
            <form @submit.prevent="editAbout" class="forms-sample">
              <div class="row">
                <div class="col-md-12">
                  <div role="group" class="form-group">
                    <label class="d-block"><Title></Title></label>
                    <div>
                      <input type="text" placeholder="Title" v-model="about.section1Title" class="form-control">
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <label class="d-block">Description</label>
                    <div>
                      <textarea v-model="about.section1Description" class="form-control" rows="8"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn mr-2 btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Chairman</h4>
            <form @submit.prevent="editAbout" class="forms-sample">
              <div class="row">
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <label class="d-block">Name</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="about.chairmanName" class="form-control">
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <label class="d-block">Designation</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="about.chairmanDesignation" class="form-control">
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <label class="d-block">Message</label>
                    <div>
                      <textarea v-model="about.chairmanMessage" class="form-control" rows="9"></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <label class="d-block">Image</label>
                    <vue-dropzone ref="myVueDropzone" id="thumb " :useCustomSlot="true" :options="thumb"
                      @vdropzone-success="chairmanImg">
                      <div class="dropzone-custom-content">
                        <div class="image-size-dropzone">
                          <div>
                            <img :src="`${imageURL}/${about.chairmanImage}`" class="show-img img-fluid" />
                          </div>
                        </div>
                        <div class="subtitle p-2">
                          <span>Change</span>
                          <span style="color: #eb2027"> Image</span>
                        </div>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn mr-2 btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">moreAbout </h4>
            <form @submit.prevent="editAbout" class="forms-sample">
              <div class="row">
                <div class="col-md-12">
                  <div role="group" class="form-group">
                    <label class="d-block">More About</label>
                    <div>
                      <textarea v-model="about.moreAbout" class="form-control" rows="5"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn mr-2 btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Loader from "../components/Loader";
import { sliderApi } from "../api";
const imageURL = process.env.VUE_APP_BASE_URL;
export default {
  name: 'about',
  components: {
    vueDropzone: vue2Dropzone, Loader,
  },
  data() {
    return {
      showLoader: false,
      imageURL,
      about: "",
      thumb: {
        url: `${imageURL}/upload`,
        maxFilesize: 1,
      },
    };
  },
  mounted() {
    this.AboutById();
  },
  methods: {

    async AboutById() {
      this.showLoader = true;
      const { data } = await sliderApi.getAboutById(`1`);
      this.about = data.data.data;
      this.showLoader = false;
    },

    ImgUpload(file, response) {
      this.about.banner = response.data
      if (response.status === 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },

    chairmanImg(file, response) {
      this.about.chairmanImage = response.data
      if (response.status === 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },

    async editAbout() {
      this.showLoader = true;
      const data = await sliderApi.updateAbout(this.about._id, {
        ...this.about,
      });
      this.AboutById();
      this.showLoader = false;
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.message,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.message,
        });
      }
    },
  }
}
</script>
